<template>
  <div id="divProfitWrapper" class="content-layout-fixer">
    <Header :text="headerContent[0].text" :headline="headerContent[0].headline"></Header>
    <VueWrapperNarrow>
      <VueForm class="form-holder" ref="form">
        <VueInput
          class="input-wrapper"
          name="numberOfVisits"
          id="numberOfVisits"
          type="text"
          label="PM satış ekibi sizi haftada kaç kere ziyaret ediyor?"
          v-model="formModel.numberOfVisits"
          :messageOnTop="true"
          validationRules="required|numeric"
          validatorName="Ziyaret sayısı"
        ></VueInput>
        <VueInput
          class="input-wrapper"
          name="averageCostPerVisit"
          id="averageCostPerVisit"
          type="text"
          label="Her ziyarette ortalama fatura tutarınız nedir?"
          v-model="formModel.averageCostPerVisit"
          :messageOnTop="true"
          validationRules="required|numeric"
          validatorName="Tutar"
        ></VueInput>
      </VueForm>
      <BrandButton
        class="calc-button"
        :size="sizes.xxLarge"
        :contentAlignment="constants.flexAlignment.center"
        type="submit"
        :padding="{ vertical: 15, horizontal: 0 }"
        @click.prevent="handleSubmit"
        >HESAPLA</BrandButton
      >
      <div v-if="showResultView" class="result-holder">
        <VueText class="turnover-amount" color="grey-40" sizeLevel="12" weightLevel="3">
          {{ resultViewItems.topValue | currencyFormat }} TL</VueText
        >
        <VueText class="customer-total-loss" color="grey-40" sizeLevel="11">
          {{ resultViewItems.topTitle }}</VueText
        >
        <VueText class="endorsement-amount" color="grey-40" sizeLevel="12" weightLevel="3">
          {{ resultViewItems.bottomValue | currencyFormat }} TL</VueText
        >
        <VueText class="turnover-total-loss" color="grey-40" sizeLevel="11">
          {{ resultViewItems.bottomTitle }}</VueText
        >
      </div>
      <BrandButton
        :outlined="true"
        v-if="showResultView"
        @click="submitResultPopUp"
        :size="sizes.xxLarge"
        >PERFORMANS TAKİBİ</BrandButton
      >
    </VueWrapperNarrow>
  </div>
</template>

<script>
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import Header from '@/components/brand/Headers/Header.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { Learn } from '@/services/Api/index';
import currencyFilter from '@/mixins/currencyFilter.js';
import gtmUtils from '@/mixins/gtmUtils';

export default {
  name: 'Endorsement',
  components: {
    Header,
    VueForm,
    VueInput,
    BrandButton,
    VueText,
    VueWrapperNarrow,
  },
  mixins: [currencyFilter, gtmUtils],
  data() {
    return {
      showResultView: false,
      headerContent: [
        {
          headline: 'Kârınızı hesaplayınız:',
          text: 'Philip Morris ile çalışmak size kazandırır!',
        },
      ],
      resultViewItems: {
        topTitle: 'Cironuz',
        topValue: 0,
        bottomTitle: 'Kârınız',
        bottomValue: 0,
      },
      formModel: {
        numberOfVisits: '',
        averageCostPerVisit: '',
      },
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    async handleSubmit() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        Learn.getAnnualProfit(
          this.formModel.numberOfVisits,
          this.formModel.averageCostPerVisit,
        ).then(res => {
          let response = res.data.Data;
          if (response) {
            this.resultViewItems.topValue = response.annualRevenue;
            this.resultViewItems.bottomValue = response.annualProfit;
            this.showResultView = true;
            this.pushDataLayerEvent('learn', {
              event: 'profit_calculation',
              pm_weekly_visit: this.formModel.numberOfVisits,
              pm_avg_amount: this.formModel.averageCostPerVisit,
              yearly_revenue: response.annualRevenue,
              yearly_profit: response.annualProfit,
            });
          }
          this.$nextTick(() => {
            const result = document.querySelector('.result-holder');
            result.scrollIntoView({ behavior: 'smooth' });
          });
        });
      }
    },
    submitResultPopUp() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.ManageMyBusiness.path}`);
    },
  },
};
</script>

<style lang="scss">
.form-holder {
  margin-top: palette-space-level(60);

  .input-wrapper {
    margin-bottom: palette-space-level(20);
  }
}

.calc-button {
  margin-top: palette-space-level(10);
}

.result-holder {
  padding: palette-space-level(30) 0 palette-space-level(45);
  margin: palette-space-level(70) 0 palette-space-level(30);
  background-color: palette-color-level('grey', '10');
  text-align: center;

  .endorsement-amount,
  .turnover-amount {
    padding-bottom: palette-space-level(30);
  }

  .customer-total-loss {
    margin-bottom: palette-space-level(80);
  }
}
</style>
